/* Ocultar los íconos predeterminados */
.angular-editor-toolbar button i {
      display: none !important;
}

/* Reemplazar íconos con Font Awesome */

/* Deshacer (Undo) */
.angular-editor-toolbar button[title='Undo']::before {
      font-family: 'Font Awesome 5 Free';
      content: '\f2ea';
      /* Unicode FontAwesome */
      font-weight: 900;
}

/* Rehacer (Redo) */
.angular-editor-toolbar button[title='Redo']::before {
      font-family: 'Font Awesome 5 Free';
      content: '\f01e';
      font-weight: 900;
}

/* Negrita (Bold) */
.angular-editor-toolbar button[title='Bold']::before {
      font-family: 'Font Awesome 5 Free';
      content: '\f032';
      font-weight: 900;
}

/* Cursiva (Italic) */
.angular-editor-toolbar button[title='Italic']::before {
      font-family: 'Font Awesome 5 Free';
      content: '\f033';
      font-weight: 900;
}

/* Subrayado (Underline) */
.angular-editor-toolbar button[title='Underline']::before {
      font-family: 'Font Awesome 5 Free';
      content: '\f0cd';
      font-weight: 900;
}

/* Tachado (Strikethrough) */
.angular-editor-toolbar button[title='Strikethrough']::before {
      font-family: 'Font Awesome 5 Free';
      content: '\f0cc';
      font-weight: 900;
}

/* Subíndice (Subscript) */
.angular-editor-toolbar button[title='Subscript']::before {
      font-family: 'Font Awesome 5 Free';
      content: '\f12c';
      font-weight: 900;
}

/* Superíndice (Superscript) */
.angular-editor-toolbar button[title='Superscript']::before {
      font-family: 'Font Awesome 5 Free';
      content: '\f12b';
      font-weight: 900;
}

/* Alinear izquierda (Justify Left) */
.angular-editor-toolbar button[title='Justify Left']::before {
      font-family: 'Font Awesome 5 Free';
      content: '\f036';
      font-weight: 900;
}

/* Alinear centro (Justify Center) */
.angular-editor-toolbar button[title='Justify Center']::before {
      font-family: 'Font Awesome 5 Free';
      content: '\f037';
      font-weight: 900;
}

/* Alinear derecha (Justify Right) */
.angular-editor-toolbar button[title='Justify Right']::before {
      font-family: 'Font Awesome 5 Free';
      content: '\f038';
      font-weight: 900;
}

/* Justificar completo (Justify Full) */
.angular-editor-toolbar button[title='Justify Full']::before {
      font-family: 'Font Awesome 5 Free';
      content: '\f039';
      font-weight: 900;
}

/* Aumentar sangría (Indent) */
.angular-editor-toolbar button[title='Indent']::before {
      font-family: 'Font Awesome 5 Free';
      content: '\f03c';
      font-weight: 900;
}

/* Disminuir sangría (Outdent) */
.angular-editor-toolbar button[title='Outdent']::before {
      font-family: 'Font Awesome 5 Free';
      content: '\f03b';
      font-weight: 900;
}

/* Lista desordenada (Unordered List) */
.angular-editor-toolbar button[title='Unordered List']::before {
      font-family: 'Font Awesome 5 Free';
      content: '\f0ca';
      font-weight: 900;
}

/* Lista ordenada (Ordered List) */
.angular-editor-toolbar button[title='Ordered List']::before {
      font-family: 'Font Awesome 5 Free';
      content: '\f0cb';
      font-weight: 900;
}

/* Color de texto (Text Color) */
.angular-editor-toolbar button[title='Text Color']::before {
      font-family: 'Font Awesome 5 Free';
      content: '\f1fc';
      font-weight: 900;
}

/* Color de fondo (Background Color) */
.angular-editor-toolbar button[title='Background Color']::before {
      font-family: 'Font Awesome 5 Free';
      content: '\f53f';
      font-weight: 900;
      color: white;
      padding: 3px 0px 4px 6px;
      background-color: black;

      span {
            display: none !important;
      }
}

/* Insertar enlace (Insert Link) */
.angular-editor-toolbar button[title='Insert Link']::before {
      font-family: 'Font Awesome 5 Free';
      content: '\f0c1';
      font-weight: 900;
}

/* Quitar enlace (Unlink) */
.angular-editor-toolbar button[title='Unlink']::before {
      font-family: 'Font Awesome 5 Free';
      content: '\f127';
      font-weight: 900;
}

/* Insertar línea horizontal (Horizontal Line) */
.angular-editor-toolbar button[title='Horizontal Line']::before {
      font-family: 'Font Awesome 5 Free';
      content: '\f068';
      font-weight: 900;
}

/* Limpiar formato (Clear Formatting) */
.angular-editor-toolbar button[title='Clear Formatting']::before {
      font-family: 'Font Awesome 5 Free';
      content: '\f12d';
      font-weight: 900;
}

/* Ver código HTML (HTML Code) */
.angular-editor-toolbar button[title='HTML Code']::before {
      font-family: 'Font Awesome 5 Free';
      content: '\f121';
      font-weight: 900;
}
