@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import './app/shared/styles/angular-editor.scss';
@import './app/shared/styles/bootstrap.scss';
@import "../node_modules/@ng-select/ng-select/themes/default.theme.css";
@import '@ctrl/ngx-emoji-mart/picker';

* {
      color: #064853 !important;
      font-family: 'Poppins', sans-serif !important;
      box-sizing: border-box;
      margin: 0;
      padding: 0;
      .fa-solid, .fa-regular, .fas, .far, .fal, .fad, .fab, .fa-brands, .fa {
            font-family: 'FontAwesome', sans-serif !important;
      }

}


.content-table {
      overflow-x: hidden;

      .table-overflow {
            overflow-x: auto;
      }
}

.tooltip {
  .tooltip-inner {
    background-color: #1fe679 !important;
    color: white !important;
  }

  // Flecha superior
  &.bs-tooltip-top .tooltip-arrow::before {
    border-top-color: #1fe679 !important;
  }

  // Flecha inferior
  &.bs-tooltip-bottom .tooltip-arrow::before {
    border-bottom-color: #1fe679 !important;
  }

  // Flecha izquierda
  &.bs-tooltip-start .tooltip-arrow::before {
    border-left-color: #1fe679 !important;
  }

  // Flecha derecha
  &.bs-tooltip-end .tooltip-arrow::before {
    border-right-color: #1fe679 !important;
  }
}


