@import "@ng-select/ng-select/themes/default.theme.css";

a {
      text-decoration: none !important;
}

p {
      font-size: 11px;
}

.text-success {
      color: #1fe679 !important;
}

.text-secondary {
      color: #00cdd5 !important;
}

.text-primary {
      color: #064853 !important;
}

.text-warning {
      color: #f6ba22 !important;
}

.text-danger {
      color: #f66b7e !important;
}

.text-purple {
      color: #a095ff !important;
}

.bg-success {
      background-color: #1fe679 !important;
      color: #fff !important;
}

.bg-secondary {
      background-color: #00cdd5 !important;
      color: #fff !important;
}

.bg-primary {
      background-color: #064853 !important;
      color: #fff !important;
}

.bg-warning {
      background-color: #f6ba22 !important;
      color: #fff !important;
}

.bg-danger {
      background-color: #f66b7e !important;
      color: #fff !important;
}

.bg-purple {
      background-color: #a095ff !important;
      color: #fff !important;
}

.bg-tertiary {
      background-color: #808000 !important;
      color: #fff !important;
}


// @import "assets/scss/app.scss";
// @import '@ctrl/ngx-emoji-mart/picker';

body {
      font-family: 'Poppins', sans-serif;
}

.btn-xs {
      font-size: 14px;
      /* padding: 4px; */
      padding: 2px 5px 2px 5px;
}

.no-scrollbar {
      overflow-x: hidden;
}

.form-container-filter {
      border: 1px solid #dddada;
      padding: 20px;
      margin: 5px;
}

.form-title-filter {
      font-size: 20px;
      margin-bottom: 10px;
}

.text-primaryy {
      color: #064853 !important;
}


.card-border {
      border: 1px solid #dddada;
      padding: 10px;
}

.card-border-tab {
      border: 1px solid #dddada;
      padding: 20px;
}

/* Estilo personalizado para aumentar el tamaño del checkbox */
.checkbox-large {
      transform: scale(1.5);
      /* Ajusta el valor según tus necesidades */
}

.bg-ziro {
      background-color: #064853 !important;
}

.text-ziro {
      color: #064853;
}

.custom-note {
      background-color: #f0f0f0;
      /* Color de fondo */
      border: 1px solid #ccc;
      /* Borde */
      padding: 10px;
      /* Espaciado interno */
      border-radius: 5px;
      /* Bordes redondeados */
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      /* Sombra */
}

.custom-note p {
      color: #333;
      /* Color de texto */
      margin: 0;
      /* Elimina el margen del párrafo */
}

.cursor-pointer {
      cursor: pointer;
}

.ng-select.custom {
      width: 100%;
      min-height: 0px;
      border-radius: 0;
}

.ng-select.custom .ng-select-container {
      min-height: 0px;
      border-radius: 0;
      border-bottom: 1px solid;
}


.ng-select.custom ::ng-deep .ng-select-container {
      min-height: 0px;
      border-radius: 0;
}

ng-select.ng-invalid.ng-touched .ng-select-container {
      border-color: #dc3545;
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px #fde6e8;
}

.ng-select.custom .ng-placeholder {
      display: block;
      color: #999;
}


/* Estilo para hacer que la tabla sea responsiva */
.table-responsive {
      overflow-x: auto;
}

/* Estilo para ajustar el ancho de las columnas */
.table {
      width: 100%;
      max-width: 100%;
      /* Asegura que la tabla no exceda el ancho del contenedor */
}

/* Estilo para hacer que las columnas se ajusten automáticamente */
.nowrap th,
.nowrap td {
      white-space: nowrap;
      /* Evita que el texto se divida en varias líneas */
}

.custom-scroll-container {
      overflow: auto;
}

.custom-scroll-container::-webkit-scrollbar {
      width: 4px;
}


.custom-scroll-container {
      overflow: auto;
}

.custom-scroll-container::-webkit-scrollbar-thumb {
      background-color: #BBFFBF;
      border-radius: 10px;
}



.text-mora {
      color: #F66B7E;
}


.emoji-mart .emoji-mart-emoji span {
      left: -.15em;
      top: -.1em;
      cursor: pointer;
}

.btn-success {
      background-color: #1fe679 !important;
      border: none;
      color: #064853 !important;

      i {
            color: #064853 !important;
      }

      &:hover {
            background-color: #55eb99 !important;
      }
}

.btn-light {
      background-color: #f4f4f4 !important;
      border: none;
      color: #064853 !important;

      i {
            color: #064853 !important;
      }

      &:hover {
            background-color: #e4e4e4 !important;
      }
}

.btn-outline-success {
      background-color: transparent;
      border: 1px solid #1fe679 !important;
      color: #064853 !important;

      i {
            color: #064853 !important;
      }

      &:hover {
            background-color: #55eb99 !important;
      }
}

.btn-secondary {
      background-color: #00cdd5 !important;
      border: none;
      color: #064853 !important;

      i {
            color: #064853 !important;
      }

      &:hover {
            background-color: #5aebf0 !important;
      }
}

.btn-primary {
      background-color: #064853 !important;
      color: #fff !important;
      border: none;

      i {
            color: #fff !important;
      }

      &:hover {
            background-color: #085866 !important;
      }
}

.btn-outline-primary {
      background-color: transparent;
      border: 1px solid #085866 !important;
      color: #085866 !important;

      i {
            color: #085866 !important;
      }

      &:hover {
            background-color: #085866 !important;

            i {
                  color: #fff !important;
            }
      }
}

.btn-outline-primary {
      border: 1px solid #064853 !important;
      color: #064853 !important;

      i {
            color: #064853 !important;
      }

      &:hover {
            border: 1px solid #085866 !important;

            i {
                  color: #085866 !important;
            }
      }
}

.btn-warning {
      background-color: #f6ba22 !important;
      border: none;
      color: #fff !important;

      i {
            color: #fff !important;
      }

      &:hover {
            background-color: #f1ce74 !important;
      }
}

.btn-danger {
      background-color: #f66b7e !important;
      border: none;
      color: #fff !important;

      i {
            color: #fff !important;
      }

      &:hover {
            background-color: #fd9aa7 !important;
      }
}

.btn-purple {
      background-color: #a095ff !important;
      border: none;
      color: #064853 !important;

      i {
            color: #064853 !important;
      }

      &:hover {
            background-color: #b2aaff !important;
      }
}

.btn-dark {
      background-color: #212529 !important;
      border: none;
      color: #fff !important;

      i {
            color: #fff !important;
      }

      &:hover {
            background-color: #272b30 !important;
      }
}

.btn-sm {
      font-size: 11px;
      margin: 0 4px 0 0;
      padding: 6px 28px;
      font-weight: 500;

}


.btn-search{
      background-color: #bbffbf !important;
      i{
            color: #064853 !important;
      }
      &:hover{
            background-color: #8ee494!important;
      }
      font-size: 14px;
      font-weight: 500;
      padding: 6px 28px;


}

.btn-circle {
      width: 28px !important;
      height: 28px !important;
      border-radius: 50%;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;

      i {
            font-size: 13px;
      }
}

.card-shadow {
      box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.15);
      padding: 15px;

      .content-table {
            overflow-x: auto;
      }
}

table {
      td, th {
            font-size: 14px;
      }
}

.badge {
      padding: 3px 5px;
      border-radius: 6px;

      &.badge-danger, &.badge-Rechazado {
            background-color: #f66b7e;
      }

      &.badge-success, &.badge-Aprobado {
            background-color: #1fe679;
      }

      &.badge-info, &.badge-Preaprobado {
            background-color: #00cdd5;
      }

      &.badge-secondary {
            background-color: #bbffbf;
      }

      &.badge-warning {
            background-color: #f6ba22;
      }

      &.badge-Solicitud {
            background-color: #a095ff;
      }
}

.btn-xs {
      width: 26px;
      height: 25px;
}

button.button-helps {
      position: relative;

      span {
            position: absolute;
            top: -32px;
            right: 0;
            background-color: #fff;
            border: 1px #00cdd5 solid;
            border-radius: 6px;
            padding: 3px 6px;
            max-width: 600px;
            width: auto;
            text-wrap: nowrap;
            transition: all 0.3s ease !important;
            display: none;
      }

      &:hover {
            span {
                  display: block;
            }
      }
}

.alert-success {
      border: 1px solid #1fe679;

      p {
            font-size: 14px;
      }
}

.alert-danger {
      border: 1px solid #f66b7e;

      p {
            font-size: 14px;
      }
}

.scroll {
      &::-webkit-scrollbar {
            height: 6px; /* Ajusta el ancho de la barra horizontal */
            width: 6px;
      }

      &::-webkit-scrollbar-thumb {
            background: #e4e4e4; /* Color de la parte arrastrable */
            border-radius: 10px; /* Redondea las esquinas de la parte arrastrable */
      }

      &::-webkit-scrollbar-thumb:hover {
            background: #d8d8d8; /* Cambia el color cuando pasas el mouse */
      }
}

.not-allowed {
      cursor: not-allowed !important;
}

.message_error{
      color: #F66B7E !important;
      font-size: 1rem;
      font-weight: 700;
}
